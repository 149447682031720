$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1d1d1d;
$foreground-color: #242424;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #767e8d;
$theme-color-2: #4d5a5f;
$theme-color-3: #444341;
$theme-color-4: #60646b;
$theme-color-5: #52595f;
$theme-color-6: #5a5953;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #787c85;
$gradient-color-2 : #666b75;
$gradient-color-3 : #6d727a;

$lp-bg-color-1 : #1c1d1d;
$lp-bg-color-2 : #1e1f1f;
$lp-bg-color-3 : #2c2d2e;
$lp-bg-color-4 : #303030;

$shadow-offsets-top : 1 3 10 14 19;
$shadow-blurs-top: 2 6 10 14 19;
$shadow-opacities-top: 0.1 0.3 0.6 0.7 0.8;

$shadow-offsets-bottom : 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.1 0.3 0.6 0.7 0.8;

$logo-path: "../../logos/white.svg";
$logo-path-mobile: "../../logos/mobile.svg";

$lp-logo-path-pinned: "../../logos/white-full.svg";
$lp-logo-path: "../../logos/white-full.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";
